import React, { useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import styled from '@emotion/styled';
import { ListingType } from '@Encore-FastStartup-Phase-Out/encore-shared';
import { useMediaQuery } from '@chakra-ui/react';

import { CompleteListing } from '../../models/Listing';
import {
  AuctionVideoWrapper,
  SmallScreenAuctionsContainer,
  SmallScreenButtonsContainer,
} from '../../screens/styledComponents';
import { Breakpoints } from '../../constants/sizes';
import PrimaryButton from '../buttons/PrimaryButton';
import Slider from '../buttons/Slider';
import { useTranslate } from '../../hooks/useTranslate';
import { useStore } from '../../hooks/useStore';
import { BodyText1 } from '../typography/BodyTexts';
import Chat from '../chat/Chat';

import Control from './Control';

const VideoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const PlayerWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

const ControlContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
`;

const StyledVideo = styled.div`
  width: 100%;
  height: 100%;

  video {
    object-fit: cover !important;
    border-radius: 12px;

    @media screen and (max-width: ${Breakpoints.Width650}) {
      border-radius: 0;
    }
  }
`;

interface VideoState {
  playing: boolean;
  muted: boolean;
  volume: number;
  played: number;
  seeking: boolean;
  buffer: boolean;
}

interface VideoPlayerProps {
  listing: CompleteListing;
}

const VideoPlayer = ({ listing }: VideoPlayerProps) => {
  const {
    uiStore: { openDownloadPopup },
  } = useStore();

  let count = 0;
  const videoPlayerRef = useRef<ReactPlayer>(null);
  const controlRef = useRef<HTMLDivElement>(null);

  const t = useTranslate();

  const [isWidth1080] = useMediaQuery([
    `(max-width: ${Breakpoints.Width1080})`,
  ]);

  const [videoState, setVideoState] = useState<VideoState>({
    playing: false,
    muted: false,
    volume: 0.5,
    played: 0,
    seeking: false,
    buffer: true,
  });

  const { playing, muted, volume, seeking } = videoState;

  const playPauseHandler = () => {
    setVideoState({ ...videoState, playing: !videoState.playing });
  };

  const progressHandler = (state: { played: number }) => {
    if (count > 3 && controlRef.current) {
      controlRef.current.style.visibility = 'hidden';
    } else if (
      controlRef.current &&
      controlRef.current.style.visibility === 'visible'
    ) {
      count += 1;
    }

    if (!seeking) {
      setVideoState({ ...videoState, played: state.played });
    }
  };

  const volumeChangeHandler = (value: number) => {
    const newVolume = value;
    setVideoState({
      ...videoState,
      volume: newVolume / 100,
      muted: newVolume === 0,
    });
  };

  const volumeSeekUpHandler = (value: number) => {
    const newVolume = value;
    setVideoState({
      ...videoState,
      volume: newVolume / 100,
      muted: newVolume === 0,
    });
  };

  const mouseMoveHandler = () => {
    if (controlRef.current) {
      controlRef.current.style.visibility = 'visible';
    }
    count = 0;
  };

  const bufferStartHandler = () => {
    setVideoState({ ...videoState, buffer: true });
  };

  const bufferEndHandler = () => {
    setVideoState({ ...videoState, buffer: false });
  };

  const secureVideoUrl = listing?.video?.replace('http://', 'https://');

  return (
    <AuctionVideoWrapper>
      <VideoContainer>
        <PlayerWrapper onMouseMove={mouseMoveHandler}>
          <StyledVideo>
            <ReactPlayer
              ref={videoPlayerRef}
              url={secureVideoUrl}
              width="100%"
              height="100%"
              playing={playing}
              volume={volume}
              muted={muted}
              onProgress={progressHandler}
              onBuffer={bufferStartHandler}
              onBufferEnd={bufferEndHandler}
              loop
              playsinline
            />
          </StyledVideo>
          <ControlContainer ref={controlRef}>
            <Control
              controlRef={controlRef}
              onPlayPause={playPauseHandler}
              playing={playing}
              onVolumeChangeHandler={volumeChangeHandler}
              onVolumeSeekUp={volumeSeekUpHandler}
              mute={muted}
              volume={volume}
            />
            {isWidth1080 && (
              <SmallScreenAuctionsContainer>
                {listing.listingType === ListingType.AUCTION && (
                  <Chat listing={listing} />
                )}
                <SmallScreenButtonsContainer>
                  <PrimaryButton
                    green
                    onClick={openDownloadPopup}
                    style={{
                      padding: '10px 16px',
                      zIndex: 2024,
                    }}
                  >
                    <BodyText1 fontWeight="700">
                      {t(
                        `auction.${listing.listingType === ListingType.AUCTION ? 'customBid' : 'makeOffer'}`,
                      )}
                    </BodyText1>
                  </PrimaryButton>
                  {listing.listingType === ListingType.AUCTION ? (
                    <Slider price={listing.price + 1} />
                  ) : (
                    <PrimaryButton
                      green
                      onClick={openDownloadPopup}
                      style={{
                        padding: '12px 40px',
                        zIndex: 2024,
                      }}
                    >
                      {`${t('auction.buyItNow')} - $${listing.price}`}
                    </PrimaryButton>
                  )}
                </SmallScreenButtonsContainer>
              </SmallScreenAuctionsContainer>
            )}
          </ControlContainer>
        </PlayerWrapper>
      </VideoContainer>
    </AuctionVideoWrapper>
  );
};

export default VideoPlayer;
