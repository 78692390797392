import React, { useEffect, useRef } from 'react';
import { Input, useMediaQuery } from '@chakra-ui/react';
import {
  EncoreColors,
  ListingType,
} from '@Encore-FastStartup-Phase-Out/encore-shared';
import { observer } from 'mobx-react-lite';

import { WebAppColors } from '../../constants/colors';
import { CompleteListing } from '../../models/Listing';
import { useStore } from '../../hooks/useStore';
import { Breakpoints } from '../../constants/sizes';
import { FooterBadges } from '../../screens/styledComponents';
import Timer from '../cards/Timer';
import { BodyText3 } from '../typography/BodyTexts';
import LoadingAnimation from '../lottie/LoadingAnimation';

import {
  ChatInputContainer,
  ChatsContainer,
  ChatWrapper,
} from './styledComponents';
import ChatItem from './ChatItem';

interface ChatProps {
  listing: CompleteListing;
}

const Chat = ({ listing }: ChatProps) => {
  const {
    uiStore: { openDownloadPopup },
    bidsStore: { auctionMessages },
    listingStore: { loadingAuctionMessages },
  } = useStore();

  const [isWidth650, isWidth1080] = useMediaQuery([
    `(max-width: ${Breakpoints.Width650})`,
    `(max-width: ${Breakpoints.Width1080})`,
  ]);

  const chatsContainerRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const scrollToBottom = () => {
    if (chatsContainerRef.current) {
      chatsContainerRef.current.scrollTop =
        chatsContainerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [auctionMessages]);

  const handleInputClick = () => {
    openDownloadPopup();

    if (inputRef.current) {
      inputRef.current.blur();
    }
  };

  return (
    <ChatWrapper>
      <ChatsContainer ref={chatsContainerRef}>
        <LoadingAnimation loading={loadingAuctionMessages} />
        {!loadingAuctionMessages &&
          auctionMessages.map((message, index) => (
            <ChatItem key={index} message={message} />
          ))}
      </ChatsContainer>
      <ChatInputContainer>
        <Input
          ref={inputRef}
          variant="outline"
          placeholder="Say something"
          size={isWidth1080 ? 'md' : 'lg'}
          style={{
            borderRadius: '12px',
            padding: '4px 18px',
            border: `1px solid ${WebAppColors.Gray50}`,
            color: EncoreColors.White,
            fontSize: '16px',
            height: isWidth1080 ? '44px' : 'auto',
            lineHeight: '24px',
            zIndex: 2024,
          }}
          _placeholder={{
            color: EncoreColors.White,
            fontSize: isWidth1080 ? '12px' : '16px',
            lineHeight: isWidth1080 ? '16px' : '24px',
          }}
          onClick={handleInputClick}
          isReadOnly
        />
        {listing.listingType === ListingType.AUCTION && isWidth650 && (
          <>
            <FooterBadges time>
              🔥
              <Timer
                endTime={listing.endTime ? listing.endTime : 0}
                format="dhms"
              />
            </FooterBadges>
            <FooterBadges>
              <BodyText3 fontWeight="700">{`$${listing.price}`}</BodyText3>
            </FooterBadges>
          </>
        )}
      </ChatInputContainer>
    </ChatWrapper>
  );
};

export default observer(Chat);
