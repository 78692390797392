import { makeAutoObservable } from 'mobx';
import { IAuctionMessage } from '@Encore-FastStartup-Phase-Out/encore-shared';

import { ListingApi } from '../api/ListingApi';
import { QueryDto } from '../constants/types';
import { CompleteListing } from '../models/Listing';

export class ListingStore {
  api: ListingApi;
  popularAuctions: CompleteListing[] = [];
  recentBuyNowListings: CompleteListing[] = [];
  endingSoonAuctions: CompleteListing[] = [];
  currentListing: CompleteListing | null = null;
  currentListingMessages: IAuctionMessage[] = [];
  userActiveListings: CompleteListing[] = [];
  userSoldListings: CompleteListing[] = [];
  loading = false;
  loadingAuctionMessages = false;

  constructor() {
    this.api = new ListingApi();
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  setPopularAuctions(auctions: CompleteListing[]) {
    this.popularAuctions = auctions;
  }

  setRecentBuyNowListings(listings: CompleteListing[]) {
    this.recentBuyNowListings = listings;
  }

  setEndingSoonAuctions(auctions: CompleteListing[]) {
    this.endingSoonAuctions = auctions;
  }

  setCurrentListing(listing: CompleteListing) {
    this.currentListing = listing;
  }

  setCurrentListingMessages(messages: IAuctionMessage[]) {
    this.currentListingMessages = messages;
  }

  setUserActiveListings(listings: CompleteListing[]) {
    this.userActiveListings = listings;
  }

  setLoading(loading: boolean) {
    this.loading = loading;
  }

  setLoadingAuctionMessages(loading: boolean) {
    this.loadingAuctionMessages = loading;
  }

  setUserSoldListings(listings: CompleteListing[]) {
    this.userSoldListings = listings;
  }

  clearUserListings() {
    this.userActiveListings = [];
    this.userSoldListings = [];
  }

  clearListingLists() {
    this.popularAuctions = [];
    this.recentBuyNowListings = [];
    this.endingSoonAuctions = [];
  }

  async getPopularAuctions(query: QueryDto) {
    const result = await this.api.getPopularAuctions(query);
    this.setPopularAuctions(result.items);
    return result.items;
  }

  async getRecentBuyNowListings(query: QueryDto) {
    const result = await this.api.getRecentBuyNowListings(query);
    this.setRecentBuyNowListings(result.items);
    return result.items;
  }

  async getEndingSoonAuctions(query: QueryDto) {
    const result = await this.api.getEndingSoonAuctions(query);
    this.setEndingSoonAuctions(result.items);
    return result.items;
  }

  async getListingById(id: string) {
    const result = await this.api.getListingById(id);
    this.setCurrentListing(result);
    return result;
  }

  async getAuctionMessagesByListing(listingId: string) {
    this.setLoadingAuctionMessages(true);

    const result = await this.api.getAuctionMessagesByListing(listingId);
    this.setCurrentListingMessages(result);
    this.setLoadingAuctionMessages(false);
    return result;
  }

  async getUserActiveListings(userId: string, pageNo?: number, limit?: number) {
    this.setLoading(true);
    const result = await this.api.getUserActiveListings(userId, {
      pageNo: pageNo || 1,
      limit: limit || 20,
    });

    if (pageNo === 1) {
      this.setUserActiveListings(result.items);
    } else {
      this.setUserActiveListings([...this.userActiveListings, ...result.items]);
    }

    this.setLoading(false);
    return result;
  }

  async getUserSoldListings(userId: string, pageNo?: number, limit?: number) {
    this.setLoading(true);
    const result = await this.api.getUserSoldListings(userId, {
      pageNo: pageNo || 1,
      limit: limit || 20,
    });

    if (pageNo === 1) {
      this.setUserSoldListings(result.items);
    } else {
      this.setUserSoldListings([...this.userSoldListings, ...result.items]);
    }

    this.setLoading(false);
    return result;
  }

  async fetchSellerListings(userId: string) {
    let userListings: CompleteListing[];
    const activeListings = await this.getUserActiveListings(userId);

    userListings = [...activeListings.items];
    if (activeListings.count < 20) {
      const buyItNowListings = await this.api.getUserBuyItNowListings(userId, {
        pageNo: 1,
        limit: 20 - activeListings.count,
      });
      userListings = [...userListings, ...buyItNowListings.items];
    }
    this.setUserActiveListings(userListings);
  }
}
