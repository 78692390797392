import { IAuctionMessage } from '@Encore-FastStartup-Phase-Out/encore-shared';

import { endpoints } from '../constants/endpoints';
import { QueryDto } from '../constants/types';

import { BaseApi } from './index';

export class ListingApi extends BaseApi<any> {
  constructor() {
    super(endpoints.listings.base);
  }

  async getPopularAuctions(query: Partial<QueryDto>) {
    return this.fetch(endpoints.listings.popularAuctions, { params: query });
  }

  async getRecentBuyNowListings(query: Partial<QueryDto>) {
    return this.fetch(endpoints.listings.recentBuyNowListings, {
      params: query,
    });
  }

  async getEndingSoonAuctions(query: Partial<QueryDto>) {
    return this.fetch(endpoints.listings.auctionsEndingSoon, { params: query });
  }

  async getListingById(id: string) {
    return this.get(endpoints.listings.byId(id));
  }

  async getAuctionMessagesByListing(
    listingId: string,
  ): Promise<IAuctionMessage[]> {
    return this.get(endpoints.listings.auctionMessagesByListing(listingId));
  }

  async getUserActiveListings(userId: string, query: Partial<QueryDto>) {
    return this.fetch(endpoints.listings.activeByUserId(userId), {
      params: query,
    });
  }

  async getUserSoldListings(userId: string, query: Partial<QueryDto>) {
    return this.fetch(endpoints.listings.soldByUserId(userId), {
      params: query,
    });
  }

  async getUserBuyItNowListings(userId: string, query: Partial<QueryDto>) {
    return this.fetch(endpoints.listings.buyItNowByUserId(userId), {
      params: query,
    });
  }
}
