import React from 'react';
import { Box, Flex, Image, Link, Text, useMediaQuery } from '@chakra-ui/react';
import { useNavigate } from 'react-router';

import EncoreLogo from '../../assets/png/encore-logo.png';
import { ReactComponent as HelpIcon } from '../../assets/svg/help.svg';
import PrimaryButton from '../buttons/PrimaryButton';
import { useTranslate } from '../../hooks/useTranslate';
import { WebAppColors } from '../../constants/colors';
import { Breakpoints } from '../../constants/sizes';
import { Routes } from '../../router/routes';
import { AuctionPageType } from '../../constants/types';

const Header = () => {
  const t = useTranslate();
  const navigate = useNavigate();

  const goToHelpCenter = () => {
    window.open('https://buyencore.zendesk.com/hc/en-us', '_blank');
  };

  const [isWidth800, isWidth1080] = useMediaQuery([
    `(max-width: ${Breakpoints.Width800})`,
    `(max-width: ${Breakpoints.Width1080})`,
  ]);

  return (
    <Box
      as="header"
      bg={WebAppColors.DarkBackground}
      py={'16px'}
      borderBottom="1px solid"
      borderColor={WebAppColors.Gray20}
      position="sticky"
      top="0"
      zIndex="2024"
    >
      <Flex
        justify="space-between"
        align="center"
        margin={'0 auto'}
        paddingX={isWidth1080 ? '40px' : '80px'}
      >
        <Link href="/">
          <Image src={EncoreLogo} alt="encore logo" width={100} />
        </Link>
        <Flex as="nav" gap={8}>
          <Text
            cursor="pointer"
            onClick={() =>
              navigate(`${Routes.Auctions}?type=${AuctionPageType.auctions}`)
            }
          >
            Auctions
          </Text>
          <Text
            cursor="pointer"
            onClick={() =>
              navigate(`${Routes.Auctions}?type=${AuctionPageType.buyItNow}`)
            }
          >
            Buy Now
          </Text>
        </Flex>
        {!isWidth800 && (
          <PrimaryButton
            green
            variant="solid"
            onClick={goToHelpCenter}
            style={{ gap: 10 }}
          >
            {t('homepage.helpCenter')}
            <HelpIcon />
          </PrimaryButton>
        )}
      </Flex>
    </Box>
  );
};

export default Header;
