import {
  AuctionMessageType,
  CreateMessageDto,
  Events,
  IAuctionMessage,
} from '@Encore-FastStartup-Phase-Out/encore-shared';
import { makeAutoObservable } from 'mobx';

import { CompleteListing } from '../models/Listing';
import { BidsApi } from '../api/BidsApi';

import { EventsStore } from './EventsStore';

export class BidsStore {
  api: BidsApi;
  auctionMessages: IAuctionMessage[] = [];
  currentBid: IAuctionMessage | undefined = undefined;
  eventsStore: EventsStore;

  constructor(eventsStore: EventsStore) {
    this.api = new BidsApi();
    this.eventsStore = eventsStore;
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  setAuctionMessages(messages: IAuctionMessage[]) {
    this.auctionMessages = messages;
  }

  /**
   * WE need to typecaset the message comming in from websockets
   * to IAuctionMessage type since we don't have the user relation
   * to display data in the chat.
   * @param message
   */
  pushAuctionMessage(message: CreateMessageDto) {
    const auctionMessage = {
      ...message,
      user: { avatar: message.avatar, userName: message.userName },
    } as unknown as IAuctionMessage;

    if (message.type === AuctionMessageType.Bid) this.setCurrentBid(message);
    this.auctionMessages = [...this.auctionMessages, auctionMessage];
  }

  setCurrentBid(currentBid: any) {
    this.currentBid = currentBid;
  }

  listenToNewAuctionMessage = (listing: CompleteListing) => {
    this.eventsStore.listenToListingEvent(Events.NewBid, listing.id, (data) => {
      this.pushAuctionMessage(data);
      console.log('socket');
    });
  };
}
