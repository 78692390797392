import React, { useEffect, useRef, useState } from 'react';
import {
  AuctionMessageType,
  IAuctionMessage,
} from '@Encore-FastStartup-Phase-Out/encore-shared';
import { useMediaQuery } from '@chakra-ui/react';

import { UserImage, UserImageWrapper } from '../cards/styledComponents';
import { images } from '../images/images';
import { Breakpoints } from '../../constants/sizes';

import {
  ChatItemContainer,
  ChatTextWrapper,
  MessageText,
  Username,
} from './styledComponents';

interface ChatItemProps {
  message: IAuctionMessage;
}

const ChatItem = ({ message }: ChatItemProps) => {
  const textWrapperRef = useRef<HTMLDivElement | null>(null);
  const [isSingleLine, setIsSingleLine] = useState(true);

  const [isWidth650, isWidth1080] = useMediaQuery([
    `(max-width: ${Breakpoints.Width650})`,
    `(max-width: ${Breakpoints.Width1080})`,
  ]);

  useEffect(() => {
    const textWrapperElement = textWrapperRef.current;

    if (textWrapperElement) {
      setIsSingleLine(
        textWrapperElement.scrollHeight === textWrapperElement.clientHeight,
      );
    }
  }, [message]);

  return (
    <ChatItemContainer
      isBid={message.type === AuctionMessageType.Bid}
      alignCenter={isSingleLine}
    >
      <UserImageWrapper
        size={isWidth650 ? '4%' : isWidth1080 ? '3%' : '7%'}
        mt={isWidth1080 ? 0 : 1}
      >
        <UserImage
          src={
            message.user?.avatar ? message.user?.avatar : images.defaultPicture
          }
          alt="User Profile Image"
        />
      </UserImageWrapper>
      {message.type === AuctionMessageType.Text ? (
        <ChatTextWrapper ref={textWrapperRef}>
          <Username>{message.user.userName}:</Username>
          <MessageText>{message.text}</MessageText>
        </ChatTextWrapper>
      ) : (
        <ChatTextWrapper ref={textWrapperRef}>
          <MessageText>{`${message.user.userName} bids `}</MessageText>
          <MessageText isBid>{`$${message.amount}`}</MessageText>
        </ChatTextWrapper>
      )}
    </ChatItemContainer>
  );
};

export default ChatItem;
