import React, { useEffect, useState } from 'react';
import { Box, useMediaQuery } from '@chakra-ui/react';
import { useNavigate } from 'react-router';
import { ArrowBackIcon, ChevronLeftIcon } from '@chakra-ui/icons';
import {
  AuctionMessageType,
  IAuctionMessage,
  ListingType,
} from '@Encore-FastStartup-Phase-Out/encore-shared';
import { observer } from 'mobx-react-lite';

import {
  AuctionInfoFooter,
  AuctionInfoWrapper,
  DescriptionContainer,
  FooterBadges,
  RatingContainer,
  SellerActionsWrapper,
  SellerInfoContainer,
  SellerInfoWrapper,
} from '../../screens/styledComponents';
import {
  ItemTitle,
  UserImage,
  UserImageWrapper,
} from '../cards/styledComponents';
import { images } from '../images/images';
import { BodyText1, BodyText2, BodyText3 } from '../typography/BodyTexts';
import PrimaryButton from '../buttons/PrimaryButton';
import { Heading4 } from '../typography/Headlines';
import { WebAppColors } from '../../constants/colors';
import Slider from '../buttons/Slider';
import Timer from '../cards/Timer';
import { CompleteListing } from '../../models/Listing';
import { ReactComponent as Star } from '../../assets/svg/star.svg';
import { useTranslate } from '../../hooks/useTranslate';
import { useStore } from '../../hooks/useStore';
import { Breakpoints } from '../../constants/sizes';
import PrimaryIconButton from '../buttons/PrimaryIconButton';
import { Routes } from '../../router/routes';

interface ListingInfoProps {
  listing: CompleteListing;
}

const ListingInfo = ({ listing }: ListingInfoProps) => {
  const t = useTranslate();
  const navigate = useNavigate();
  const {
    uiStore: { openDownloadPopup },
    bidsStore: { auctionMessages },
  } = useStore();
  const [priceToDisplay, setPriceToDisplay] = useState<number>(listing.price);
  const [bids, setBids] = useState<IAuctionMessage[]>([]);

  const [isWidth650] = useMediaQuery([`(max-width: ${Breakpoints.Width650})`]);

  useEffect(() => {
    if (listing.listingType === ListingType.AUCTION) {
      const bidsForListing = auctionMessages.filter(
        (auctionMessage) => auctionMessage.type === AuctionMessageType.Bid,
      );
      setBids(bidsForListing);
    }
  }, [listing, auctionMessages]);

  useEffect(() => {
    if (bids.length > 0) {
      const currentBid = bids[bids.length - 1];
      if (currentBid.amount) setPriceToDisplay(currentBid.amount);
    }
  }, [bids]);

  return (
    <AuctionInfoWrapper>
      {!isWidth650 && (
        <PrimaryButton
          transparent
          leftIcon={<ArrowBackIcon boxSize={6} />}
          onClick={() => navigate(-1)}
          style={{
            justifyContent: 'flex-start',
            padding: '0 16px 0 0',
            width: 'fit-content',
          }}
        >
          <BodyText2 fontWeight="800">{t('common.back')}</BodyText2>
        </PrimaryButton>
      )}

      <SellerActionsWrapper>
        <SellerInfoContainer
          onClick={() =>
            navigate(
              `${Routes.Seller}/${encodeURIComponent(listing.user?.userName)}`,
            )
          }
        >
          {isWidth650 && (
            <PrimaryIconButton
              icon={<ChevronLeftIcon />}
              aria-label={'go-back-button'}
              fontSize="22px"
              marginRight="-8px"
              marginLeft="-14px"
              transparent
              onClick={() => navigate(-1)}
            />
          )}
          <UserImageWrapper size={'20%'}>
            <UserImage
              src={
                listing.user?.avatar
                  ? listing.user?.avatar
                  : images.defaultPicture
              }
              alt="User Profile Image"
            />
          </UserImageWrapper>
          <SellerInfoWrapper>
            <ItemTitle>{listing.user?.userName}</ItemTitle>
            <RatingContainer>
              <Box as={Star} width={4} height={4} />
              <BodyText3 textTransform="none">
                {`${listing.user?.avgRate || 0} (${
                  listing.user?.totalRatings || 0
                })`}
              </BodyText3>
            </RatingContainer>
          </SellerInfoWrapper>
        </SellerInfoContainer>
        <PrimaryButton
          green
          style={{
            padding: '10px 18px',
          }}
          onClick={openDownloadPopup}
        >
          {t('user.follow')}
        </PrimaryButton>
      </SellerActionsWrapper>
      <Heading4 fontWeight="700">{listing.title}</Heading4>
      <DescriptionContainer>
        <BodyText1 fontWeight="700">{t('auction.description')}</BodyText1>
        <BodyText2 color={WebAppColors.Gray60}>{listing.description}</BodyText2>
      </DescriptionContainer>
      {!isWidth650 && (
        <>
          <PrimaryButton outlined onClick={openDownloadPopup}>
            {t(
              `auction.${listing.listingType === ListingType.AUCTION ? 'customBid' : 'makeOffer'}`,
            )}
          </PrimaryButton>
          {listing.listingType === ListingType.AUCTION ? (
            <Slider price={priceToDisplay + 1} />
          ) : (
            <PrimaryButton green onClick={openDownloadPopup}>
              {t('auction.buyItNow')}
            </PrimaryButton>
          )}
          {listing.listingType === ListingType.AUCTION && (
            <AuctionInfoFooter>
              <FooterBadges time>
                🔥
                <Timer
                  endTime={listing.endTime ? listing.endTime : 0}
                  format="dhms"
                />
              </FooterBadges>
              <FooterBadges>
                <BodyText3 fontWeight="700">{`$${priceToDisplay}`}</BodyText3>
              </FooterBadges>
            </AuctionInfoFooter>
          )}
        </>
      )}
    </AuctionInfoWrapper>
  );
};

export default observer(ListingInfo);
