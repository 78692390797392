import React from 'react';
import styled from '@emotion/styled';
import { Box } from '@chakra-ui/react';
import { EncoreColors } from '@Encore-FastStartup-Phase-Out/encore-shared';

import { BodyText1 } from '../typography/BodyTexts';
import { useTranslate } from '../../hooks/useTranslate';
import { useStore } from '../../hooks/useStore';
import { Breakpoints } from '../../constants/sizes';

import PrimaryButton from './PrimaryButton';

const SliderWrapper = styled(Box)`
  display: flex;
  padding: 4px 16px 4px 4px;
  justify-content: space-between;
  align-items: center;
  border-radius: 12px;
  border: 1px solid ${EncoreColors.Primary};
  background: ${EncoreColors.Primary30};

  @media screen and (max-width: ${Breakpoints.Width1080}) {
    width: 70%;
  }
`;

interface SliderProps {
  price: number;
}

const Slider = ({ price }: SliderProps) => {
  const t = useTranslate();
  const {
    uiStore: { openDownloadPopup },
  } = useStore();

  return (
    <SliderWrapper>
      <PrimaryButton
        green
        style={{
          borderRadius: '10px',
          padding: '8px 10%',
          height: '35px',
          zIndex: 2024,
        }}
        onClick={openDownloadPopup}
      >
        <BodyText1 fontWeight="700">{t('auction.bid')}</BodyText1>
      </PrimaryButton>
      <BodyText1 fontWeight="700">{`$${price}`}</BodyText1>
    </SliderWrapper>
  );
};
export default Slider;
