import React, { useEffect, useState } from 'react';
import { Box, useMediaQuery } from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import { ListingType } from '@Encore-FastStartup-Phase-Out/encore-shared';

import AuctionsPreview from '../components/itemBars/AuctionsPreview';
import { useStore } from '../hooks/useStore';
import VideoPlayer from '../components/video-player/VideoPlayer';
import ListingInfo from '../components/common/ListingInfo';
import Chat from '../components/chat/Chat';
import OpenInAppPopup from '../components/common/OpenInAppPopup';
import { getListingLink } from '../utils/adjustLinking';
import SellerListings from '../components/itemBars/SellerListings';
import LoadingAnimation from '../components/lottie/LoadingAnimation';
import { Breakpoints } from '../constants/sizes';

import { AuctionScreenWrapper } from './styledComponents';

const AuctionScreen = () => {
  const {
    listingStore: {
      currentListing,
      getListingById,
      fetchSellerListings,
      getAuctionMessagesByListing,
      currentListingMessages,
    },
    eventsStore: { unsubscribeToAllListingEvents },
    bidsStore: { listenToNewAuctionMessage, setAuctionMessages },
  } = useStore();

  const [isWidth1080] = useMediaQuery([
    `(max-width: ${Breakpoints.Width1080})`,
  ]);

  const { id, sharerId } = useParams<{ id: string; sharerId?: string }>();
  const location = useLocation();
  const [openInAppPopupLink, setOpenInAppPopupLink] = useState<string>('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      if (id && (!currentListing?.id || currentListing?.id !== id)) {
        setLoading(true);
        await getListingById(id);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    })();

    const queryParams = new URLSearchParams(location.search);
    const sharerId = queryParams.get('sharerId');
    if (id && sharerId) {
      const link = getListingLink(id, sharerId);
      setOpenInAppPopupLink(link);
    }

    return () => {
      if (currentListing) {
        unsubscribeToAllListingEvents(currentListing.id);
      }
    };
  }, [id]);

  useEffect(() => {
    if (currentListing) {
      unsubscribeToAllListingEvents(currentListing.id);
      listenToNewAuctionMessage(currentListing);
      setAuctionMessages(currentListingMessages);

      if (currentListing.listingType === ListingType.DEFAULT) {
        setLoading(true);
        fetchSellerListings(currentListing.user.id).finally(() => {
          setTimeout(() => setLoading(false), 1000);
        });
      }
    }
    return () => {
      if (currentListing) {
        unsubscribeToAllListingEvents(currentListing.id);
      }
    };
  }, [currentListing, currentListingMessages]);

  useEffect(() => {
    if (currentListing && currentListing.listingType === ListingType.AUCTION) {
      getAuctionMessagesByListing(currentListing.id);
    }
  }, [currentListing]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentListing]);

  if (!currentListing) {
    return null;
  }

  return (
    <Box>
      {openInAppPopupLink && <OpenInAppPopup linkToOpen={openInAppPopupLink} />}
      <LoadingAnimation loading={loading} />
      {!loading && (
        <AuctionScreenWrapper>
          <>
            <ListingInfo listing={currentListing} />
            <VideoPlayer listing={currentListing} />
            {!isWidth1080 &&
              (currentListing.listingType === ListingType.AUCTION ? (
                <Chat listing={currentListing} />
              ) : (
                <SellerListings />
              ))}
          </>
        </AuctionScreenWrapper>
      )}
      <AuctionsPreview />
    </Box>
  );
};

export default observer(AuctionScreen);
