import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { observer } from 'mobx-react-lite';
import { useMediaQuery } from '@chakra-ui/react';

import { Routes } from '../../router/routes';
import { AuctionsContainer } from '../../screens/styledComponents';
import { useTranslate } from '../../hooks/useTranslate';
import { useStore } from '../../hooks/useStore';
import { AuctionPageType } from '../../constants/types';
import { Breakpoints } from '../../constants/sizes';

import ItemsShowcase from './ItemsShowcase';

const AuctionsPreview = () => {
  const [loading, setLoading] = useState(false);

  const t = useTranslate();
  const navigate = useNavigate();

  const [isWidth800] = useMediaQuery([`(max-width: ${Breakpoints.Width800})`]);

  const {
    listingStore: {
      getPopularAuctions,
      popularAuctions,
      getEndingSoonAuctions,
      endingSoonAuctions,
      getRecentBuyNowListings,
      recentBuyNowListings,
    },
  } = useStore();

  useEffect(() => {
    (async () => {
      setLoading(true);
      await getPopularAuctions({ pageNo: 1, limit: 21 });
      await getEndingSoonAuctions({ pageNo: 1, limit: 21 });
      await getRecentBuyNowListings({ pageNo: 1, limit: 21 });
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    })();
  }, []);

  return (
    <AuctionsContainer>
      {popularAuctions.length > 0 && (
        <ItemsShowcase
          title={t('auctions.popularAuctions')}
          onViewAllClick={() =>
            navigate(
              `${Routes.Auctions}?type=${AuctionPageType.popularAuctions}`,
            )
          }
          listings={popularAuctions}
          loading={loading}
        />
      )}

      {endingSoonAuctions.length > 0 && (
        <ItemsShowcase
          title={t('auctions.endingSoon')}
          onViewAllClick={() =>
            navigate(`${Routes.Auctions}?type=${AuctionPageType.endingSoon}`)
          }
          listings={endingSoonAuctions}
          loading={loading}
        />
      )}
      {recentBuyNowListings.length > 0 && (
        <ItemsShowcase
          title={t('auctions.buyItNow')}
          onViewAllClick={() =>
            navigate(`${Routes.Auctions}?type=${AuctionPageType.buyItNow}`)
          }
          listings={recentBuyNowListings}
          loading={loading}
        />
      )}
    </AuctionsContainer>
  );
};

export default observer(AuctionsPreview);
